class ChildMutationEvent extends Event {
  constructor() {
    super('childmutation', {bubbles: true})
  }
}

class AttributeChangedEvent extends Event {
  constructor() {
    super('attributechanged', {bubbles: true})
  }
}

const childObserver = new MutationObserver(mutations => {
  for (const mutation of mutations) {
    if (mutation.type == 'childList')
      mutation.target.dispatchEvent(new ChildMutationEvent())
    else if (mutation.type == 'attribute')
      mutation.target.dispatchEvent(new AttributeChangedEvent())
  }
})

export class ImageSelect extends HTMLElement {
  connectedCallback() {
    if (document.readyState == 'loading')
      return document.addEventListener('readystatechange', this.connectedCallback, {once: true})
    this.attachShadow({mode: 'open'})
    childObserver.observe(this, { childList: true, subtree: true, attributes: true, attributeFilter: ['multiple'] })
    this.addEventListener('childmutation', () => this.render())
    this.addEventListener('attributechanged', () => this.render())
    this.addEventListener('input', () => this.updateSelection())
    this.addEventListener('search', () => { this.render() })

    this.render()
  }

  render() {
    this.shadowRoot.replaceChildren()
    for (const sheet of document.styleSheets) {
      this.shadowRoot.append(sheet.ownerNode.cloneNode(true))
    }
    const template = document.createElement('template')
    template.innerHTML = '<slot name=before part=before></slot>'
    this.shadowRoot.append(template.content)

    Array.from(this.querySelectorAll('option')).filter(this.search).forEach(option => {
      const template = document.createElement('template')
      template.innerHTML = `
        <div part="item" data-value=${option.value}>
          <h3 part="name">${option.innerText}</h3>
          <figure part="figure">
            <img src=${option.dataset.imgSrcFront} part="image">
            <figcaption part="caption">${option.dataset.imgCaptionFront}</figcaption>
          </figure>
          <figure part="figure">
            <img src=${option.dataset.imgSrcBack} part="image">
            <figcaption part="caption">${option.dataset.imgCaptionBack}</figcaption>
          </figure>
        </div>`
      template.content.firstElementChild.addEventListener('click', () => {
        if (this.multiple)
          option.selected = !option.selected
        else
          option.selected = true
        this.querySelector('select')
          .dispatchEvent(new Event('input', {bubbles: true}))
      })
      this.shadowRoot.append(template.content)
    })

    this.updateSelection()
  }

  get search() {
    const params = new URLSearchParams(window.location.search)
    const string = params.get('search')?.toLowerCase()
    if (string)
      return element => element.innerText.toLowerCase().match(string)
    else
      return () => true
  }

  updateSelection() {
    const values = this.values
    for (const item of this.shadowRoot.children) {
      item.part.toggle('selected', values.has(item.dataset.value))
    }
  }

  get value() {
    return this.querySelector('select').value
  }

  get values() {
    return new Set([...this.querySelector('select')].filter(o => o.selected).map(o => o.value))
  }

  get multiple() {
    return this.querySelector('select').multiple
  }

  set value(val) {
    this.querySelector('select').value = val
  }
}

export default ImageSelect
